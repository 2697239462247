import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {DashboardExternalLink} from '../pages/dashboard/DashboardExternalLink'

export function PrivateRoutes() {
  const ProfileData = lazy(() => import('../pages/menu_pelamar/ProfileData'))
  const Lowongan = lazy(() => import('../pages/menu_pelamar/Lowongan'))
  const HistoryLowongan = lazy(() => import('../pages/menu_pelamar/HistoryLowongan'))
  const DatabaseGeneralPage = lazy(() => import('../wrapper_page/DatabaseGeneralPage'))
  const DatabaseKepegawaianPage = lazy(() => import('../wrapper_page/DatabaseKepegawaianPage'))
  const DatabaseRekrutmenPage = lazy(() => import('../wrapper_page/DatabaseRekrutmenPage'))
  const DatabaseRekananPage = lazy(() => import('../wrapper_page/DatabaseRekananPage'))
  const DatabaseStrukturOrganisasiPage = lazy(() => import('../wrapper_page/DatabaseStrukturOrganisasiPage'))
  const KepegawaianRekrutmenPage = lazy(() => import('../wrapper_page/KepegawaianRekrutmenPage'))
  const KepegawaianLaporanPage = lazy(() => import('../wrapper_page/KepegawaianLaporanPage'))
  const ToolsPage = lazy(() => import('../wrapper_page/ToolsPage'))
  const PengadaanAdminPage = lazy(() => import('../wrapper_page/PengadaanAdminPage'))
  const MenuVendorPage = lazy(() => import('../wrapper_page/MenuVendorPage'))
  const KeuanganMasterDataPage = lazy(() => import('../wrapper_page/KeuanganMasterDataPage'))
  const KeuanganTransaksiPage = lazy(() => import('../wrapper_page/KeuanganTransaksiPage'))
  const KeuanganLaporanPage = lazy(() => import('../wrapper_page/KeuanganLaporanPage'))
  const InventoryMasterDataPage = lazy(() => import('../wrapper_page/InventoryMasterDataPage'))
  const InventoryTransaksiPage = lazy(() => import('../wrapper_page/InventoryTransaksiPage'))
  const InventoryLaporanPage = lazy(() => import('../wrapper_page/InventoryLaporanPage'))
  const ManajemenAssetMasterPage = lazy(() => import('../wrapper_page/ManajemenAssetMasterPage'))
  const ManajemenAssetPemeliharaanPage = lazy(() => import('../wrapper_page/ManajemenAssetPemeliharaanPage'))
  const ManajemenAssetKalibrasiPage = lazy(() => import('../wrapper_page/ManajemenAssetKalibrasiPage'))
  const ManajemenAssetPengaturanPage = lazy(() => import('../wrapper_page/ManajemenAssetPengaturanPage'))
  const ManajemenAssetLaporanPage = lazy(() => import('../wrapper_page/ManajemenAssetLaporanPage'))
  const ScanInventaris = lazy(() => import('../pages/manajemen_asset/master_data/inventaris/ScanInventaris'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* <Route path='/pedoman' component={Pedoman} /> */}
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/database/general' component={DatabaseGeneralPage} />
        <Route path='/database/kepegawaian' component={DatabaseKepegawaianPage} />
        <Route path='/database/rekrutmen' component={DatabaseRekrutmenPage} />
        <Route path='/database/rekanan' component={DatabaseRekananPage} />
        <Route path='/database/organisasi' component={DatabaseStrukturOrganisasiPage} />
        <Route path='/kepegawaian/rekrutmen' component={KepegawaianRekrutmenPage} />
        <Route path='/kepegawaian/laporan' component={KepegawaianLaporanPage} />
        <Route path='/tools' component={ToolsPage} />
        <Route path='/vendor' component={MenuVendorPage} />
        <Route path='/pengadaan/eproc' component={PengadaanAdminPage} />
        
        <Route path='/fin/master-data' component={KeuanganMasterDataPage} />
        <Route path='/fin/transaksi' component={KeuanganTransaksiPage} />
        <Route path='/fin/laporan' component={KeuanganLaporanPage} />
        <Route path='/pengadaan/master-data' component={InventoryMasterDataPage} />
        <Route path='/pengadaan/transaksi' component={InventoryTransaksiPage} />
        <Route path='/pengadaan/laporan' component={InventoryLaporanPage} />
        <Route path='/asset/master/inventaris/scan-qrcode/:id' exact component={ScanInventaris}/>
        <Route path='/asset/master' component={ManajemenAssetMasterPage} />
        <Route path='/pemeliharaan/master' component={ManajemenAssetPemeliharaanPage} />
        <Route path='/asset/pengaturan' component={ManajemenAssetPengaturanPage} />
        <Route path='/kalibrasi' component={ManajemenAssetKalibrasiPage} />
        <Route path='/laporan' component={ManajemenAssetLaporanPage} />

        <Route path='/pelamar-data-diri' component={ProfileData} />
        <Route path='/pelamar-lowongan' component={Lowongan} />
        <Route path='/pelamar-history-lowongan' component={HistoryLowongan} />
        <Route path='/external-link/:id' exact component={DashboardExternalLink}/>
        <Route path='/external-link2/:id' exact component={DashboardExternalLink}/>
        <Redirect from='/beranda' to='/dashboard' />
        {/* <Redirect from='/auth' to='/dashboard' /> */}
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
