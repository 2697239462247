import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import package_json from '../../../../../package.json'
import { useHistory, useLocation } from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const location = useLocation()
  const history = useHistory()

  // We should request user by authToken before rendering the application
  useEffect(() => {
    console.log(package_json.version)
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          localStorage.setItem('role', JSON.stringify(user.data.role))
          localStorage.setItem('modul', JSON.stringify(user.data.module_access))
          if (user.data?.version !== package_json.version) {
            const arr_ver_api = user.data?.version.split(".")
            const arr_ver_client = package_json.version.split(".")
            let err_status = false
            if (Number(arr_ver_api[0]) > Number(arr_ver_client[0])) {
              err_status = true
            }
            else {
              if (Number(arr_ver_api[1]) > Number(arr_ver_client[1])) {
                err_status = true
              }
              else {
                if (Number(arr_ver_api[2]) > Number(arr_ver_client[2])) {
                  err_status = true
                }
              }
            }
            // console.log('version front end: ' + package_json.version)
            console.log('version api: ' + user.data?.version)
            if (err_status) toast.error(CustomToastWithLink, {position: toast.POSITION.TOP_RIGHT, autoClose: false});
          }
          dispatch(props.fulfillUser(user))
          if (user.data?.role !== undefined) {
            // console.log(user.data?.role[0].nama.toLowerCase())
            dispatch(props.fulfillRole(user.data.role[0].nama.toLowerCase()))
          }
          if (localStorage.getItem('last-path') !== null) {
            if (localStorage.getItem('last-path') !== "/") {
              history.push(localStorage.getItem('last-path') || "/")
            }
          }
          localStorage.removeItem('last-path')
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          // console.log('api verify gagal')
          localStorage.clear()
          localStorage.setItem('last-path', location.pathname)
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }
    // toast.error(CustomToastWithLink, {position: toast.POSITION.TOP_RIGHT, autoClose: false})
    if (accessToken) {
      // console.log('if access token')
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  const reloadPage = () => {
    console.log('reload')
    localStorage.clear();
    localStorage.setItem('last-path', location.pathname)
    if('caches' in window){
      // console.log('test')
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      // dispatch(auth.actions.logout())
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload();
    }
  }

  const CustomToastWithLink = () => (
    <div onClick={()=>reloadPage()}>Versi belum terupdate, mohon melakukan hard refresh (ctrl + f5) atau klik notifikasi ini untuk melakukan refresh!</div>
  );

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
