import axios from 'axios'

// export const URL = `${process.env.REACT_APP_API_URL}md-rekanan`
export const URL = `md-rekanan`

export function FetchVendor(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/info', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function ShowVendorByID(id: any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-by-id/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchVendorDefault(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-rekanan', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchVendorType(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-rekanan-type/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchListDokumen(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-urgen-dokumen', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchPaktaIntegritas(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/template-integritas', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchMasterInputVendor(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/general/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateProfil = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-profil', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateAktaPendirian = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-akta-pendirian', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteAktaPendirian = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-akta-pendirian', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateNpwp = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-npwp', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePaktaIntegritas = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-pakta-integritas', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

// export const UpdatePakta = (payload: any) => {
//     let formData = new FormData();

//     Object.keys(payload).forEach(function(key) {
//         formData.append(key, payload[key])
//     });

//     return new Promise((resolve, reject) => {
//         axios.post(URL + '/update-pakta', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(error => {
//                 reject(error.response);
//             });
//     })
// }
export const UpdateSiup = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-siup', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateIzinLokasi = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-ijin-lokasi', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateTdp = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-tdp', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateSijuk = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-sijuk', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteSijuk = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-sijuk', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateTKDN = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-tkdn', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteTKDN = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-tkdn', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateIzinLain = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-ijin-lain', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteIzinLain = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-ijin-lain', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePemegangSaham = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-pemilik-saham', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePemegangSaham = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-pemilik-saham', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePengurusPerusahaan = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-pengurus', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePengurusPerusahaan = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-pengurus', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePic = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-pic', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePic = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-pic', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateAlatVendor = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-alat-mesin', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteAlatVendor = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-alat-mesin', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateTenagaAhli = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-tenaga-ahli', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteTenagaAhli = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-tenaga-ahli', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKatalog = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-katalog', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKatalog = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-katalog', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKompetensi = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-kompetensi', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKompetensi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-kompetensi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePengalaman = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-pengalaman', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePengalaman = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-pengalaman', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateRekeningBank = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-rekening-bank', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteRekeningBank = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-rekening-bank', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateRekeningKoran = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-rekening-koran', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteRekeningKoran = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-rekening-koran', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateLaporanKeuanganNeraca = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-laporan-keuangan', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteLaporanKeuanganNeraca = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-laporan-keuangan', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateLaporanKeuanganLabaRugi = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-laporan-keuangan-lr', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteLaporanKeuanganLabaRugi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-laporan-keuangan-lr', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateLaporanKeuanganLain = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-laporan-keuangan-lain', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteLaporanKeuanganLain = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-laporan-keuangan-lain', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateSppkp = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-sppkp', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteSppkp = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-sppkp', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateSpt = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-spt', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteSpt = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-spt', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKeteranganFiskal = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-keterangan-fiskal', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKeteranganFiskal = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-keterangan-fiskal', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateHSSE = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL + '/update-laporan-hsse', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteHSSE = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-laporan-hsse', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VerifikasiDokumen = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL + '/verifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UnverifikasiDokumen = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL + '/batal-verifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePenyalurAlat = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update-penyalur-alat', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePenyalurAlat = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-penyalur-alat/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateRegistrasiAlat = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update-registrasi-alat', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteRegistrasiAlat = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-registrasi-alat/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdatePenunjukanPabrik = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update-penunjukan-pabrik', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeletePenunjukanPabrik = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-penunjukan-pabrik', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SendEmail = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/send-email', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchDokumenExpired(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/info-dokumen-expired', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const BanVendor = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/banned-unbanned-vendor/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ApprovalVendor = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/approve', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

