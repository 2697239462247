import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  email: '',
  company_id: '1',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword(props:any) {
  const [loading, setLoading] = useState(false)
  const [statusApi, setStatusApi] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email, values.company_id)
        .then((res:any) => {
          setLoading(false)
          // toast.success("Reset Password Sukses !", {position: toast.POSITION.TOP_RIGHT});
          if (JSON.parse(res.data.message).length > 0) {
            if (typeof JSON.parse(res.data.message) === 'string') {
              toast.success(JSON.parse(res.data.message), {position: toast.POSITION.TOP_RIGHT})
            }
            else {
              JSON.parse(res.data.message).map( function(o:any) {
                return toast.success(JSON.parse(o), {position: toast.POSITION.TOP_RIGHT})
              })
            }
          }
          else {
            toast.success("Reset Password Sukses !", {position: toast.POSITION.TOP_RIGHT});
          }

          setStatusApi(true)
          setStatusMessage("Reset Password Sukses")
          setStatusColor("success")
          setTimeout(
            () => {
              props.setTabOpen('login')
            },
            2000
          )
        })
        .catch(err => {
          // console.log(err.data.message)
          if (err !== undefined && err?.data?.message !== null && err?.data?.message !== "") {
            if (JSON.parse(err?.data?.message || '').length > 0) {
              if (typeof JSON.parse(err?.data?.message || '') === 'string') {
                toast.error(JSON.parse(err?.data?.message || ''), {position: toast.POSITION.TOP_RIGHT})
              }
              else {
                JSON.parse(err?.data?.message || '').map( function(o:any) {
                  return toast.error(JSON.parse(o), {position: toast.POSITION.TOP_RIGHT})
                })
              }
            }
            else {
              toast.error("Update Data Gagal !", {position: toast.POSITION.TOP_RIGHT});
            }
          }
          else {
            toast.error("Update Data Gagal !", {position: toast.POSITION.TOP_RIGHT});
          }
          setLoading(false)
          setSubmitting(false)
          setStatus(err.data.message)

          setStatusApi(true)
          setStatusMessage(err.data.message)
          setStatusColor('danger')
        })
      // setTimeout(() => {
      // }, 1000)

      // setTimeout(() => {
      //   requestPassword(values.email)
      //     .then(({data: {result}}) => {
      //       setHasErrors(false)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setHasErrors(true)
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_password_reset_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Lupa Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Masukkan email untuk reset password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {statusApi && (
        <div className={'mb-lg-15 alert alert-'+ statusColor}>
          <div className='alert-text font-weight-bold'>{statusMessage}</div>
        </div>
      )}

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>reset password terkirim. mohon cek email anda</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center mb-5'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={formik.isSubmitting || !formik.isValid}
            >
            <span className='indicator-label'>Submit {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} </span>
          </button>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => props.setTabOpen('login')}
          >
            Kembali
          </button>
        </div>
        <div className="m-t-20 text-center">
          {"Kembali ke Informasi"} <a href={props.link_url.includes('admin') ? "/rekrutmen/beranda" : "#"} onClick={() => props.setTabOpen('main')}>{"di sini"}</a>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
