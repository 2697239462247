/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'rea  ct-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import { useHistory } from 'react-router-dom'
// import Select from "react-select";
// import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {LoginOption} from './LoginOption'

const loginSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email('Wrong email format')
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Email is required'),
  userid: Yup.string()
    .min(3, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  application_type: Yup.string()
    .required('Role is required'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function Login(props:any) {
  const app_type =
    props.link_url.includes('admin') ? (process.env.REACT_APP_BASE_APPLICATION_TYPE_ADMIN || '1') :
    props.link_url.includes('erecruitment') ? (process.env.REACT_APP_BASE_APPLICATION_TYPE_RECRUITMENT || '2') :
    (process.env.REACT_APP_BASE_APPLICATION_TYPE_PROCUREMENT || '3')

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [statusApi, setStatusApi] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [statusColor, setStatusColor] = useState('')
  // console.log(props.dataRole)

  const initialValues = {
    // userid: props.link_url.includes("admin") ? 'admin@phc.co.id' : '',
    // password: props.link_url.includes("admin") ? 'adminphc' : '',
    userid: '',
    password: '',
    application_type: app_type,
  }
  

  // const customStyles = {
  //   menu: (provided:any, _state:any) => ({
  //     ...provided,
  //     padding: 0,
  //     margin: 0,
  //   }),
  // }

  const dispatch = useDispatch()
    const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // console.log(values)
      setLoading(true)
      setStatusApi(false)
      login(values.userid, values.password, values.application_type)
        .then((res:any) => {
          setLoading(false)
          let sidebar_menu = true
          // console.log(res)
          const data = res.data.data
          const role = data.role[0]

          if (role.nama.toLowerCase() === 'pelamar') {
            sidebar_menu = false
          }
          data.user.sidebar_menu = sidebar_menu

          localStorage.setItem('token', data.token)
          localStorage.setItem('user', JSON.stringify(data.user))
          // localStorage.setItem('role', JSON.stringify(data.role))
          // localStorage.setItem('modul', JSON.stringify(data.module_access))
          localStorage.setItem('aplikasi', JSON.stringify(data.aplikasi))
          // const obj = JSON.parse(localStorage.user)
          // console.log(obj)
          
          setStatusApi(true)
          setStatusMessage("Login Sukses")
          setStatusColor("success")
          // dispatch(auth.actions.fulfillRole(role.nama.toLowerCase()))
          setTimeout(
            () => {
              dispatch(auth.actions.login(data.token))
              window.location.reload()
            },
            2000
          )
        })
        .catch(err => {
          setLoading(false)
          setSubmitting(false)
          if(err === undefined) {
            setStatus("connection not found.")
            setStatusMessage("connection not found.")
          }
          if(err?.data !== undefined) {
            setStatus(err.data.message)
            setStatusMessage(err.data.message)
          }
          setStatusApi(true)
          setStatusColor('danger')
        })
    },
  })

  // const handleChangeRole = (selectedOption:any) => {
  //   formik.setFieldValue('application_type', selectedOption === null ? "" : selectedOption.value)
  // }
  
  const back = () => {
    if (props.link_url.includes('admin')) {
      if (props.link_url.includes('erecruitment')) {
        history.push("/erecruitment")
      }
      else if (props.link_url.includes('eprocurement')) {
        history.push("/eprocurement")
      }
      else (
        history.push("/")
      )
    }
    else {
      props.setTabOpen('main')
    }
  }
  
  const register = () => {
    props.setTabOpen('register')
  }
  
  const forgotPassword = () => {
    props.setTabOpen('forgot')
  }
  
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-4'>
        <h1 className='text-dark'>Login Sistem {props.link_url.includes('admin') ? "Admin" : (props.link_url.includes('erecruitment') ? "Rekrutmen" : "Pengadaan")}</h1>
        <h1 className='text-dark'>PT PHC Surabaya</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}
      {!props.link_url.includes('admin') &&
        <div className='text-center mb-2'>
          <div className='text-gray-400 fw-bold fs-4'>
            Belum punya akun ?{' '}
            <button type="button" className='btn btn-link p-0 link-primary fw-bolder' onClick={() => register()}>Buat akun baru</button>
          </div>
        </div>
      }

      {statusApi && (
        <div className={'mb-lg-4 alert alert-'+ statusColor}>
          <div className='alert-text font-weight-bold'>{statusMessage}</div>
        </div>
      )}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.email}</span>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('userid')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.userid && formik.errors.userid},
            {
              'is-valid': formik.touched.userid && !formik.errors.userid,
            }
          )}
          name='userid'
          autoComplete='off'
        />
        {formik.touched.userid && formik.errors.userid && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.userid}</span>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fs-6 fw-bolder text-dark mb-0'>Password</label>
            {!props.link_url.includes('admin') &&
              <div className='btn btn-link p-0 ms-2 link-primary fw-bolder' onClick={() => forgotPassword()}>Lupa Password ?</div>
            }
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <input
        placeholder='Role'
        {...formik.getFieldProps('application_type')}
        name='application_type'
        type='hidden'
        required={true}
      />
      {/* <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Login sebagai</label>
        <Select
          options={props.dataRole}
          isClearable
          name={"role"}
          onChange={handleChangeRole}
          isDisabled={props.form_disabled}
          styles={customStyles}
        />
        <input
          placeholder='Role'
          {...formik.getFieldProps('application_type')}
          name='application_type'
          type='hidden'
          required={true}
        />
        {formik.touched.application_type && formik.errors.application_type && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.application_type}</span>
          </div>
        )}
      </div> */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Masuk {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} </span>
        </button>
        <div className="m-t-20">
          {"Kembali ke Informasi"} <button type="button" className='btn btn-link p-0' onClick={() => back()}>{"di sini"}</button>
        </div>
        
        {/* <LoginOption/> */}

      </div>
      {/* end::Action */}
    </form>
  )
}
